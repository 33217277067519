import React from 'react'
import { Center, Heading, Box, useColorModeValue } from '@chakra-ui/react'
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

interface HeroProps {
  title?: string;
  image: IGatsbyImageData;
  maxH?: string;
  maxW?: string;
}

export default function Hero3({ title, image, maxH = "40vh", maxW="66%" }: HeroProps) {
  const textBg = useColorModeValue('whiteAlpha.700', 'blackAlpha.500')
  return (

    <Box
      position='relative'
      mb="8"
      minH="10vh"
      overflow="hidden"
    //background="purple.400"
    >
      <Center
        minH="30vh"
        maxH={maxH}
      >
        <Box maxW={[null, maxW]}>
        <GatsbyImage image={image} alt={title||'no description'} />
        </Box>
        {/* <img src='https://placekitten.com/1000/300' /> */}
      </Center>
      <Box
        position='absolute'
        left="50%"
        //marginLeft="-50%"
        top="50%"

        // top={['0','50%']}
        // marginTop={['0','-3%']}
        transform="translate(-50%, -50%)"


        textAlign="center"
        w='full'
        background={textBg}>
        <Heading fontSize={['3xl', '5xl', '6xl', '7xl']}
          textTransform="uppercase" letterSpacing="0.15em">{title}</Heading>
      </Box>
    </Box>
  )
}


