import * as React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/react'

declare module 'react' {
  namespace JSX {
    interface IntrinsicElements {
      'lite-youtube': {
        style: object,
        videoid: string,
        playlabel: string,
        params: string,
      }
    }
  }
}
interface YoutubeProps {
  src: string,
  label: string,
  params: string
}

const Youtube = ({ src, label, params }: YoutubeProps) => {
  const [isVisible, setVisible] = React.useState(true)
  const onClick = () => setVisible(false)
  return (
    <>
      <Box my={4} position='relative' onClick={onClick}>
        <lite-youtube
          style={{ margin: 'auto' }}
          videoid={src}
          playlabel={label}
          params={params}
        ></lite-youtube>
        {isVisible && (
          <Alert status='warning' flexDirection={['column']} className='moo'>
            <Flex direction='row' justify='start'>
              <AlertIcon />
              <AlertTitle fontSize='md'>Video embedded from YouTube</AlertTitle>
            </Flex>
            <AlertDescription fontSize='sm'>
              By clicking the video you consent to Google placing cookies on
              your computer.
            </AlertDescription>
          </Alert>
        )}
      </Box>
    </>
  )
}
export default Youtube
