import React from 'react'
import { graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link } from 'gatsby'
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Layout from '../components/layout'

import Hero3 from '../components/hero3'
import Youtube from '../components/youtube'
import Player from '../components/audioPlayer'

import {
  Text,
  Heading,
  Container,
  ListItem,
  OrderedList,
  UnorderedList,
  Center,
  TextProps,
  HeadingProps,
  ListItemProps,
  LinkProps,
} from '@chakra-ui/react'
import { Link as Wrapper } from '@chakra-ui/react'

const P = (props: TextProps) => <Text py={6} {...props} />
const H1 = (props: HeadingProps) => <Heading size='4xl' py='6' {...props} />
const H2 = (props: HeadingProps) => <Heading size='2xl' py='4' {...props} />
const UL = (props: ListItemProps) => <UnorderedList py='2' pl='6' {...props} />
const OL = (props: ListItemProps) => <OrderedList py='2' {...props} />
const LI = (props: ListItemProps) => <ListItem {...props} />
const A = (props: LinkProps) => <Wrapper {...props} />

const shortcodes = {
  Link,
  Youtube,
  Player,
  p: P,
  h1: H1,
  h2: H2,
  ul: UL,
  ol: OL,
  li: LI,
  a: A,
} // Provide common components here
const PortfolioTemplate = ({ data }: PageType) => {
  const image = getImage(data.mdx.frontmatter.coverimg)

  return (
    <Layout>
      <Hero3 title={data.mdx.frontmatter.title} image={image} />
      <Container>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{data.mdx.body}</MDXRenderer>
        </MDXProvider>
        <Center px='2'>
          <Text>
            <Wrapper as={Link} to='/portfolio'>
              Back to portfolio
            </Wrapper>
          </Text>
        </Center>
      </Container>
    </Layout>
  )
}
export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        coverimg {
          childImageSharp {
            gatsbyImageData(width: 1000)
          }
        }
      }
    }
  }
`
interface PageType {
  data: {
    mdx: {
      id: any
      body: any
      frontmatter: {
        title: string
        coverimg: IGatsbyImageData
      }
    }
  }
}

export default PortfolioTemplate
