import React from "react"
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import './audioPlayerStyles.css'
import { Text, Center, Box } from '@chakra-ui/react'

interface PlayerProps {
    src: string;
    description?: string;
}

export default function Player({src, description}: PlayerProps) {

    return (
        <>
            <Box>
                <AudioPlayer
                    style={{
                        backgroundColor: "inherit",
                        border: "none",
                        color: "inherit",
                    }}
                    preload="metadata"
                    src={src}
                    showJumpControls={false}
                    layout="stacked"
                    customProgressBarSection={
                        [
                            RHAP_UI.CURRENT_TIME,

                            RHAP_UI.PROGRESS_BAR,
                            RHAP_UI.DURATION,

                        ]
                    }

                    customAdditionalControls={[]}
                    customVolumeControls={[]} />
                <Center>
                    <Text>
                        {description}
                    </Text>
                </Center>
            </Box>
        </>
    )
}